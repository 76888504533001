<template>
  <div class="form-textarea">
    <div>
      <textarea
        :id="id"
        v-model="inputValue"
        :name="name"
        :class="['form-field', {'has-value': !!inputValue}, {'has-error': !!errorMessage}]"
        :rows="rows"
        :cols="cols"
        :required="required"
        :disabled="disabled"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${name}-error`"
      />
      <label
        v-if="label"
        :for="id"
        class="form-field-label"
      >
        {{ label }}
      </label>
    </div>
    <p v-if="!!errorMessage" :id="`${name}-error`" role="alert" class="form-error">{{ errorMessage }}</p>
  </div>
</template>

<script setup>

import { useField } from 'vee-validate';

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  required: {
    type: Boolean,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
  rules: {
    type: [String, Object],
    required: false,
    default: '',
  },
  rows: {
    type: String,
    required: false,
    default: ''
  },
  cols: {
    type: String,
    required: false,
    default: ''
  },
});

const label = computed(() => {
  return props.label + (props.required ? ' *' : '');
})

const rules = computed(() => {
  if (props.rules) return props.rules;
  if (props.required) return 'required';
});

const {
  value: inputValue,
  errorMessage,
} = useField(props.name, rules.value);

</script>

<style lang="scss" scoped>
.form-textarea {
  textarea {
    resize: none;
  }

  .form-field {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    height: auto;
  }
  .form-field:not(:focus):not(.has-value) {
    & + .form-field-label {
      top: 1.25rem;
      @include for-tablet-landscape-up {
        top: 1.875rem;
      }
    }
  }
}
</style>