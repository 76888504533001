<template>
  <div class="form-select" :class="[{ 'is-open': isOpen }]">
    <div class="select-backdrop" />
    <fieldset ref="select" class="select-input">
      <button
        ref="button"
        :class="[
          'select-button',
          'form-field',
          { 'has-value': !!inputValue },
          { 'has-error': !!errorMessage },
        ]"
        type="button"
        role="combobox"
        aria-labelledby="select button"
        aria-haspopup="listbox"
        :aria-expanded="isOpen"
        :aria-controls="`${id}-select-dropdown`"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${name}-error`"
        :disabled="disabled"
        tabindex="0"
      >
        {{ isFormBuilder ? inputLabel : inputValue }}
      </button>
      <legend class="form-field-label">{{ label }}</legend>
      <ul :id="`${id}-select-dropdown`" role="listbox" class="select-dropdown">
        <li
          v-for="(option, i) in options"
          :key="`${name}-${i}`"
          role="option"
          class="select-option"
        >
          <input
            :id="`${id}-option-${i}_${option.label}`"
            ref="inputs"
            v-model="inputValue"
            type="radio"
            :name="name"
            :value="isFormBuilder ? option.id : option"
          />
          <label :for="`${id}-option-${i}_${option.label}`" class="size-s">
            {{ isFormBuilder ? option.label : option }}
          </label>
        </li>
      </ul>
    </fieldset>
    <p
      v-if="!!errorMessage"
      :id="`${name}-error`"
      role="alert"
      class="form-error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";
import { useDropdownNavigation } from "@/composables/DropdownNavigation";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: "",
  },
  id: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
  required: {
    type: Boolean,
    required: false,
  },
  rules: {
    type: [String, Object],
    required: false,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  options: {
    type: Array,
    required: true,
  },
  isFormBuilder: {
    type: Boolean,
    required: false,
  },
  isMyself: {
    type: Boolean,
    required: false,
    default: false,
  },
  updatedValue: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
});

const label = computed(() => {
  return props.label + (props.required ? " *" : "");
});

const rules = computed(() => {
  if (props.rules) return props.rules;
  if (props.required) return "required";
});

const { value: inputValue, errorMessage } = useField(props.name, rules);

const button = ref(null);

const select = ref(null);

const inputs = ref(null);

const inputLabel = ref(null);

const emit = defineEmits(["selectVal"]);

const onSelectMenuitem = (item) => {
  inputValue.value = item.value;
  inputLabel.value = item.id.split("_").at(-1);
};

const { showDropdown: isOpen } = useDropdownNavigation(
  button,
  inputs,
  select,
  onSelectMenuitem,
  true
);

watch(
  isOpen,
  () => {
    if(isOpen.value === true) {
      setTimeout(() => {
        document.querySelector(".form-select.is-open")?.parentNode.parentNode.classList.add("select-opened");
      });
    } else {
      document.querySelector(".form-select.is-open")?.parentNode.parentNode.classList.remove("select-opened");
    }
  }
);

watch(
  () => props.isMyself,
  () => {
    if(props.isMyself) {
      if(props.name === 'CivInterloc' && inputValue.value !== undefined) {
        emit('selectVal', [inputValue.value, inputLabel.value]);
      }
    } else {
      if(props.name === 'CivGuest') {
        inputValue.value = "";
        inputLabel.value = "";
      }
    }
  }
);

watch(
  () => inputValue.value,
  () => {
    if(props.name === 'CivInterloc' && props.isMyself) {
      emit('selectVal', [inputValue.value, inputLabel.value]);
    }
  }
);

watch(
  () => props.updatedValue,
  () => {
    if(props.name === 'CivGuest' && props.updatedValue.length) {
      inputValue.value = props.updatedValue[0];
      inputLabel.value = props.updatedValue[1];
    }
  }
);
</script>

<style lang="scss" scoped>
.form-select {
  &.is-open .select-backdrop {
    content: "";
    position: fixed;
    @include inset(0);
    background-color: $background-overlay-light;
    z-index: 1;
  }

  &:not(.is-open) .select-dropdown {
    visibility: hidden;
  }

  &.is-open .select-input {
    z-index: 1;
  }

  .select-input {
    position: relative;
  }

  .select-button {
    position: relative;
    text-align: start;
  }
  .select-button:not(:disabled) {
    cursor: pointer;
  }
  .select-button,
  .form-field-label {
    padding-right: 2.8rem;
    @include truncate;
  }
  .select-button::before {
    content: "";
    background-image: url("../assets/svg/func_down.svg");
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 1.125rem;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;

    @include for-tablet-landscape-up {
      right: 1.5rem;
    }
  }
  .select-dropdown {
    position: absolute;
    @include pair-7;
    border-radius: $radius-xs;
    width: 100%;
    margin-top: 0.625rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;

    @include for-tablet-landscape-up {
      border-radius: $radius-l;
      max-height: 260px;
      overflow-y: auto;
    }
  }
  .select-option {
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 4.375rem;
      padding: 0 1.125rem;

      @include for-tablet-landscape-up {
        height: 5rem;
        padding: 0 2.25rem;
      }
    }

    input:focus ~ label {
      @include pair-2;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
