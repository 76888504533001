import { useCustomAsyncStoryblok } from "./CustomAsyncStoryblok";
import { useRuntimeConfig } from 'nuxt/app'

export async function useLimitInputField() {
    const config = useRuntimeConfig();

    const settings = await useCustomAsyncStoryblok("cdn/datasource_entries", {
        datasource: `${config.public["storyblokCountryPrefix"]}-country-settings`,
        cv: Date.now(),
        per_page: 1000,
      })
        .then((response) => response.value?.datasource_entries)
        .catch((error) => {
        console.log(error);
      });

  const limit = settings?.filter((entry) => {
    return entry.name === "form-input-characters-fields-limit";
  });

  return limit && limit.length ? limit[2]?.value : null;
}
