<template>
  <div class="form-input">
    <div>
      <input
        v-if="type === 'hidden'"
        :id="id"
        :name="name"
        :type="type"
        :value="value"
      />
      <input
        v-else
        :id="id"
        v-model="inputValue"
        :name="name"
        :type="type"
        :class="['form-field', {'has-value': !!inputValue}, {'has-error': !!errorMessage}]"
        :maxLength="maxLength"
        :required="required"
        :disabled="disabled"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${name}-error`"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <label
        v-if="label"
        :for="id"
        class="form-field-label"
      >
        {{ label }}
      </label>
    </div>
    <p v-if="!!errorMessage" :id="`${name}-error`" role="alert" class="form-error">{{ errorMessage }}</p>
  </div>
</template>

<script setup>

import { useField } from 'vee-validate';

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: 'text',
    validator (value) {
      return [
        'url',
        'text',
        'password',
        'tel',
        'search',
        'number',
        'email',
        'datetime-local',
        'date',
        'time', 
        'hidden'
      ].includes(value)
    }
  },
  focusedType: {
    type: String,
    required: false,
    default: 'text',
  },
  maxLength: {
    type: String,
    required: false,
    default: '',
  },
  required: {
    type: Boolean,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
  rules: {
    type: [String, Object],
    required: false,
    default: '',
  },
  value: {
    type: String,
    required: false,
    default: '',
  },
  isMyself: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isDesktop = inject("isDesktop");

const label = computed(() => {
  return props.label + (props.required ? ' *' : '');
})

const rules = computed(() => {
  if (props.rules) return props.rules;

  const array = [
    { name: 'required', value: props.required },
    { name: 'tel', value: props.type === 'tel' },
    { name: 'email', value: props.type === 'email' },
  ];

  return array.filter(item => item.value).map(item => item.name).join('|');
});

const {
  value: inputValue,
  errorMessage,
} = useField(props.name, rules.value);

const lastNameValue = ref(null);
const firstNameValue = ref(null);

const inputDuplicate = (sourceInput, duplicateInput, value) => {
  value = sourceInput?.value;

  if(duplicateInput) {
    if(sourceInput.value !== duplicateInput.value) {
      duplicateInput.value = value;
      duplicateInput.dispatchEvent(new Event('input'));
    }

    if(value === "") {
      duplicateInput.classList.remove("has-value");
    } else {
      duplicateInput.classList.add("has-value");
    }
  }
}

const inputRestore = (duplicateInput, value) => {
  if(duplicateInput) {
    if(duplicateInput.value !== "") {
      value = "";
      duplicateInput.value = "";
      duplicateInput.dispatchEvent(new Event('input'));
    }
    
    duplicateInput.classList.remove("has-value");
  }
} 

const handleInput = (e) => {
  if (props.type === 'tel') {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^+\d]+|(?<!^)\+/g, '');
    inputValue.value = sanitizedValue;
  }

  if(props.isMyself && e.target.name === "LastNameInterloc") {
    inputDuplicate(e.target, e.target.closest(".form").querySelector("input[name='LastNameGuest']"), lastNameValue.value);

    document.querySelectorAll(".inquiry-modal").forEach(element => {
      inputDuplicate(e.target, element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
    });

    document.querySelectorAll(".custom-form-modal").forEach(element => {
      inputDuplicate(e.target, element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
    });
  }

  if(props.isMyself && e.target.name === "FirstNameInterloc") {
    inputDuplicate(e.target, document.querySelector(".form-duplication input[name='FirstNameGuest']"), firstNameValue.value);

    document.querySelectorAll(".inquiry-modal").forEach(element => {
      inputDuplicate(e.target, element.querySelector("input[name='FirstNameGuest']"), firstNameValue.value);
    });

    document.querySelectorAll(".custom-form-modal").forEach(element => {
      inputDuplicate(e.target, element.querySelector("input[name='FirstNameGuest']"), firstNameValue.value);
    });
  }
};

const handleFocus = (e) => {
  if(isDesktop && props.type !== props.focusedType) {
    e.target.setAttribute('type', props.focusedType)
  }
}

const handleBlur = (e) => {
  if(isDesktop && props.type !== props.focusedType) {
    e.target.setAttribute('type', props.type);
  }
}

watch(
  () => props.isMyself,
  () => {
    if(props.isMyself) {
      inputDuplicate(document.querySelector(".form-duplication input[name='LastNameInterloc"), document.querySelector(".form-duplication input[name='LastNameGuest']"), lastNameValue.value);
      inputDuplicate(document.querySelector(".form-duplication input[name='FirstNameInterloc']"), document.querySelector(".form-duplication input[name='FirstNameGuest']"), lastNameValue.value);

      document.querySelectorAll(".inquiry-modal").forEach(element => {
        inputDuplicate(element.querySelector("input[name='LastNameInterloc']"), element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
        inputDuplicate(element.querySelector("input[name='FirstNameInterloc']"), element.querySelector("input[name='FirstNameGuest']"), lastNameValue.value);
      });

      document.querySelectorAll(".custom-form-modal").forEach(element => {
        inputDuplicate(element.querySelector("input[name='LastNameInterloc']"), element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
        inputDuplicate(element.querySelector("input[name='FirstNameInterloc']"), element.querySelector("input[name='FirstNameGuest']"), lastNameValue.value);
      });
    } else {
      inputRestore(document.querySelector(".form-duplication input[name='LastNameGuest']"), lastNameValue.value);
      inputRestore(document.querySelector(".form-duplication input[name='FirstNameGuest']"), firstNameValue.value);

      document.querySelectorAll(".inquiry-modal").forEach(element => {
        inputRestore(element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
        inputRestore(element.querySelector("input[name='FirstNameGuest']"), firstNameValue.value);
      });

      document.querySelectorAll(".custom-form-modal").forEach(element => {
        inputRestore(element.querySelector("input[name='LastNameGuest']"), lastNameValue.value);
        inputRestore(element.querySelector("input[name='FirstNameGuest']"), firstNameValue.value);
      });
    }
  }
);
</script>